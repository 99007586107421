<template>
    <cms-component v-bind="$props" :link="component.link" :style="containerStyle">
        <i class="glyph-component" :class="[fontAwesomePrefix, glyph]" :style="glyphStyle"/>
    </cms-component>
</template>

<script>
import CmsComponent from "@/components/editor/rendering/components/cms-component.vue";

// cms-component-glyph #hot-reload-debug
export default {
    name: `cms-component-glyph`,
    extends: CmsComponent,
    components: {CmsComponent},
    computed: {
        glyph() {
            let glyph = this.component.glyph;
            if (this.useBinding && this.component.glyphField) {
                glyph = this.boundItem[this.component.glyphField];
            }
            return glyph;
        },
        containerStyle() {
            let style = {};
            style.padding = `${this.component.mediaProxy.spacing}`;
            return style;
        },
        glyphStyle() {
            return {
                fontSize: `${this.component.mediaProxy.size}px`,
                color: this.component.mediaProxy.color
            };
        }
    }
}
</script>

<style lang="scss" scoped>
.cms-component-glyph {
    color: var(--scheme-paragraph-color);
}
</style>
